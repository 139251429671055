import React from 'react';
import './styles.css';

const Modals = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-start pt-56 md:pt-1 md:p-32 justify-center bg-gray-500 bg-opacity-75 overflow-x-auto">
      <div className="p-6 bg-white">
        <div className="">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modals;
