import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import Sidebar from './SideBar';
import Bookings from './Bookings';
import Navbar from './Navbar';
import Events from './Events';
import Connection from './Connection';
import Users from './Users';
import Device from './Device';
import Companies from './Companies';
import Actions from './Actions';
import Profile from './Profile';
import Help from './Help';
import Dashboard from './Dashboard';
import EntroKeypad from './EntroKeypad';
import ViewSMSLog from './SMSViewLog';
import { useSingleSession } from './Auth/useSingleSession';
import MetricsVisualizer from './Monitoring/MetricsVisualizer';

const SystemAdmin = () => {
  useSingleSession();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('userId');
  const userData = location.state?.userData;
  const storedRole = localStorage.getItem('role');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Store current path on page change
  useEffect(() => {
    sessionStorage.setItem('lastPath', location.pathname);
  }, [location]);

  // Ensure proper navigation to last visited path on refresh
  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    } else {
      const lastPath = sessionStorage.getItem('lastPath') || '/admin/dashboard';
      history.replace(lastPath);
    }
  }, [isAuthenticated, history]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="flex">
      <div className="hidden md:block">
        <Sidebar userData={userData} />
      </div>

      {/* Main content section */}
      <div className="flex-1 bg-gray-100 flex flex-col">
        <div className="block lg:hidden md:hidden">
          <Navbar userData={userData} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>

        <Switch>
          <Route path="/admin/dashboard" exact>
            <Dashboard userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/booking">
            <Bookings userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/sms">
            <ViewSMSLog userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/keypad">
            <EntroKeypad userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/connections">
            <Connection userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/devices">
            <Device userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/events">
            <Events userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/actions">
            <Actions userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/users">
            <Users userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/help">
            <Help userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/profile">
            <Profile userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/companies">
            <Companies userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/monitors">
            <MetricsVisualizer userData={userData} setIsMenuOpen={setIsMenuOpen} />
          </Route>
          <Route path="/admin/profile">
                <Profile userData={userData} setIsMenuOpen={setIsMenuOpen} />
            </Route>

          <Redirect exact from="/admin" to="/admin/dashboard" />
        </Switch>
      </div>
    </div>
  );
};

export default SystemAdmin;