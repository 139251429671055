import React from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';

const BookingTable = ({ data }) => {
    console.log("Data received in BookingTable:", data, Array.isArray(data));

    const columns = React.useMemo(
        () => [
            { Header: 'Booking ID', accessor: 'bookingId' },
            { Header: 'Start Date', accessor: 'startDate'},
            { Header: 'Start Time', accessor: 'startTime' },
            { Header: 'End Time', accessor: 'endTime' },
            { Header: 'End Date', accessor: 'endDate' },
            { Header: 'Booking Code', accessor: 'bookingCode' },
            { Header: 'Customer Name', accessor: 'customerName' },
            { Header: 'Customer Phone', accessor: 'customerPhone' },
            { Header: 'Company Email', accessor: 'companyEmail' },
            { Header: 'Connection', accessor: 'connection' },
            { Header: 'Company', accessor: 'company' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        currentPageDisplay,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
        state: { pageIndex, pageSize },
    } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    return (
        <>
            <div className="flex justify-end p-4">
                    <input
                        type="text"
                        value={state.globalFilter || ''}
                        onChange={e => setGlobalFilter(e.target.value)}
                        placeholder={`Search all columns...`}
                        className="border text-gray-600 px-2 py-1"
                    />
            </div>
            <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination text-black flex justify-end space-x-1 mt-2">
                <span>
                    Rows per page:
                    <select
                        value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}
                        className="mx-1"
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </span>
                <span className="flex-grow text-right">
                    {currentPageDisplay}
                </span>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-2">
                    {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="px-2">
                    {'<'}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage} className="px-2">
                    {'>'}
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-2">
                    {'>>'}
                </button>
            </div>
        </>
    );
};

export default BookingTable;
