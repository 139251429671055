import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const HttpRequestsChart = ({ metrics }) => {
  const data = [
    {
      name: "HTTP Requests",
      value: metrics.http_requests_total,
    },
  ];

  return (
    <LineChart
      width={400}
      height={200}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="value" stroke="#82ca9d" />
    </LineChart>
  );
};

export default HttpRequestsChart;