import axios from 'axios';
import mqtt from 'mqtt';
const host = `http://${window.location.hostname}`;

const isDevelopment = process.env.NODE_ENV === 'development';
const BASE_API_URL = 'http://127.0.0.1:5000/api';

const ROWS_ENDPOINT = '/data';

export const getRowsAsync = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}${ROWS_ENDPOINT}`);
    const rowsData = response.data;
    return rowsData;
  } catch (error) {
    console.error('Error fetching rows:', error);
    throw error;
  }
};

const getHost = () => {
  if (isDevelopment) {
    // Use the deployed link during development
    return 'http://213.181.119.12:9997';
  } else {
    // Use the deployed link in production
    return 'https://register-code.onrender.com';
  }
};

const getHostDoor = () => {
  if (isDevelopment) {
    return 'https://door-api-alf1.onrender.com';
  } else {
    return 'https://booking-door-lock.onrender.com'
  }
}

const getHostEvent = () => {
  if (isDevelopment) {
    return 'https://door-lock-events.onrender.com';
  } else {
    return 'https://door-lock-events.onrender.com'
  }
}

const getHostBooking = () => {
  if (isDevelopment) {
    return 'https://eunitstest.onrender.com/api';
  } else {
    return 'https://eunitstest.onrender.com/api'
  }
}

const getUser = () => {
  if (isDevelopment) {
    return 'https://user-deploy.onrender.com';
  } else {
    return 'https://user-deploy.onrender.com'
  }
}

const getHostBookingWeb = () => {
  if (isDevelopment) {
    return 'https://eunitstest.onrender.com/api';
  } else {
    return 'https://eunitstest.onrender.com/api'
  }
}

// export const getCodes = async () => {
//   console.log('... getting codes');
//   const url = `${getHost()}/api/codes`;
//   const response = await axios.get(url);
//   return response.data;
// };

export const getEvents = async () => {
  console.log('... getting events');
  const url = `https://eunitstest.onrender.com/api/geteventdetails`;
  const response = await axios.get(url)
  return response.data
}

export const getUsers = async () => {
  console.log('... getting user');
  const url = `${getUser ()}/get_users`;
  const response = await axios.get(url)
  return response.data
}

export const addEvent = async event => {
  console.log('... adding event');
  const url = `${getHostEvent()}/api/events/new`;
  const response = await axios.post(url, {...event})
  return response.data
}

export const getBookings = async () => {
  console.log('... getting bookings');
  const url = `${getHostBooking()}/getbooking`;
  const response = await axios.get(url)
  return response.data
}

export const getBookingsWeb = async () => {
  console.log('... getting bookings');
  const url = `${getHostBookingWeb()}/getbooking`;
  try {
    const response = await axios.get(url);
    console.log("API Response:", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return { message: [] };
  }
}

export const postNewBooking = async (formData) => {
  console.log('... adding booking')
  const url = `${getHostBookingWeb()}/addbooking`;
  const response = await axios.post(url, {...formData})
  return response.data
}

// export const newCode = async (code) => {
//   console.log('... creating new code');
//   const url = `${getHost()}/api/codes/new`;
//   const response = await axios.post(url, { ...code });
//   return response.data;
// };

// export const deleteCode = async (id) => {
//   console.log('... deleting code');
//   const url = `${getHost()}/api/codes/delete/${id}`;
//   const response = await axios.post(url);
//   return response.data;
// }

export const openDoor = async () => {
  console.log('... opening door');
  const url = `${getHostDoor()}/api/io`;
  const response = await axios.post(url)
  return response.data
}

const BASE_URL = 'https://authentication-api-ij8q.onrender.com';

export const signupApi = async (username, email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/signup`, { username, email, password });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.errors || 'Signup failed');
  }
};

export const loginApi = async (email, password) => {
  try {
    const response = await fetch(`https://entro-api.onrender.com/api/postlogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Invalid email or password, please try again');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Login failed: ${error.message}`);
  }
};

export const logoutApi = async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    throw new Error('Token is missing');
  }

  try {
    const response = await fetch(`${BASE_URL}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.errors || 'Logout failed');
    }

  } catch (error) {
    throw new Error(`Logout failed: ${error.message}`);
  }
};


const BASE_UR = 'https://entro-api.onrender.com/api';

export const resetPasswordRequestApi = async (email) => {
  try {
    const response = await fetch(`${BASE_UR}/emailvalidate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      throw new Error(errorData.errors || 'Reset password request failed');
    }
  } catch (error) {
    throw new Error('Reset password request failed: ' + error.message);
  }
};

export const setNewPasswordApi = async (resetToken, newPassword) => {
  try {
    const response = await axios.post(`${BASE_UR}/updatepassword`, {
      resetToken,
      newPassword,
    });

    return {
      success: response.data.success,
      message: response.data.message,
    };
  } catch (error) {

    return {
      success: false,
      message: error.response?.data.message || 'Error setting new password',
    };
  }
};

const API_BASE_URL = 'https://authentication-api-ij8q.onrender.com'; 

export const getPendingUsersApi = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/pending-users`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch pending users');
    }

    const data = await response.json();
    const isAdmin = data && data.role === 'admin';

    return { isAdmin, data };
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const approveUserApi = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/approve-user/${userId}`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to approve user');
    }

    return response.json();
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const getAssignedAdminsApi = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/assigned-admins`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch assigned admins');
    }

    return response.json();
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const assignAdminApi = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/assign-admin/${userId}`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to assign admin');
    }

    return response.json();
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const assignAdminByEmailApi = async (email) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/assign-admin-by-email`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to assign admin by email');
    }

    return response.json();
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const removeAdminApi = async (adminId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/remove-admin/${adminId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to remove admin');
    }

    return response.json();
  } catch (error) {
    throw new Error(`API Error: ${error.message}`);
  }
};

export const getUserApprovalStatusApi = async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    throw new Error('Token not found');
  }

  try {
    const response = await fetch(`${API_BASE_URL}/user-approval-status`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch approval status');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Error fetching approval status: ${error.message}`);
  }
};



// const events = [
//   {id: 1, timestamp: 1624641169804, code: 123456, status: false, user: 'unknown'},
//   {id: 2, timestamp: 1624641169804, code: 123456, status: true, user: 'Test'},
//   {id: 7, timestamp: 1624641169804, code: 123456, status: false, user: 'unknown'},
// ]

// const bookings = [
//   {status: '1', startsAtTime: 1620324000, endsAtTime: 1620325799, booking_id: 232082, contactName: null, contactPhone: null},
//   {status: '1', startsAtTime: 1624600800, endsAtTime: 1624602599, booking_id: 263266, contactName: null, contactPhone: null},
//   {status: '1', startsAtTime: 1621414800, endsAtTime: 1621416599, booking_id: 232085, contactName: null, contactPhone: null},
// ]

// const codes = [
//   {id: 1, name: "Ppito", code: 123456, start: "06:00", end: "18:00"},
//   {id: 2, name: "Boss",  code: 123456, start: "06:00", end: "18:00"},
//   {id: 3, name: "Peito", code: 123456, start: "06:00", end: "18:00"},
// ]
 
