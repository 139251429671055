import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const CpuMemoryChart = ({ metrics }) => {
  const data = [
    {
      name: "CPU User",
      value: metrics.process_cpu_user_seconds_total,
    },
    {
      name: "CPU System",
      value: metrics.process_cpu_system_seconds_total,
    },
    {
      name: "Memory",
      value: metrics.process_resident_memory_bytes / 1024 / 1024,
    },
  ];

  return (
    <LineChart
      width={400}
      height={200}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="value" stroke="#8884d8" />
    </LineChart>
  );
};

export default CpuMemoryChart;