import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const MessageSizeHistogram = ({ metrics }) => {
  if (!metrics.mqtt_message_size_bytes) {
    return <p>No message size data available.</p>;
  }

  const prepareHistogramData = (buckets) => {
    const sortedBuckets = Object.keys(buckets)
      .map((le) => parseFloat(le))
      .sort((a, b) => a - b);
  
    const data = [];
    let previousCount = 0;
  
    sortedBuckets.forEach((le, index) => {
      const count = buckets[le] - previousCount;
      data.push({
        name: `≤ ${le} bytes`,
        value: count,
      });
      previousCount = buckets[le];
    });
  
    return data;
  };
  
  const { buckets } = metrics.mqtt_message_size_bytes;
  const data = prepareHistogramData(buckets);

  return (
    <BarChart
      width={400}
      height={200}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="value" fill="#8884d8" name="Message Size Distribution" />
    </BarChart>
  );
};

export default MessageSizeHistogram;