import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const GcDurationChart = ({ metrics }) => {
  if (!metrics.nodejs_gc_duration_seconds) {
    return <p className="text-gray-600">No GC duration data available.</p>;
  }

  const { sum, count } = metrics.nodejs_gc_duration_seconds;

  const data = [
    {
      name: "Minor GC",
      count: count.minor || 0,
      sum: sum.minor || 0,
    },
    {
      name: "Incremental GC",
      count: count.incremental || 0,
      sum: sum.incremental || 0,
    },
    {
      name: "Major GC",
      count: count.major || 0,
      sum: sum.major || 0,
    },
  ];

  return (
    <BarChart
      width={400}
      height={200}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" fill="#8884d8" name="GC Count" />
      <Bar dataKey="sum" fill="#82ca9d" name="GC Duration (s)" />
    </BarChart>
  );
};

export default GcDurationChart;