import React from 'react';
import PropTypes from 'prop-types';

const MetricsTable = React.memo(({ metrics }) => {
  if (!metrics) {
    return <p className="text-gray-600">No metrics available.</p>;
  }

  const tableData = [
    { metric: "Memory Usage (bytes)", value: metrics.memory_usage_bytes || 0 },
    { metric: "MQTT Broker Uptime (seconds)", value: metrics.mqtt_broker_uptime_seconds || 0 },
    { metric: "TLS Handshake Failures", value: metrics.mqtt_tls_handshake_failures_total || 0 },
    { metric: "Network Bandwidth (bytes)", value: metrics.network_bandwidth_bytes || 0 },
    { metric: "Delivered Messages", value: metrics.mqtt_delivered_messages_total || 0 },
    { metric: "MQTT Messages Received", value: metrics.mqtt_messages_received_total || 0 },
    { metric: "Active Connections", value: metrics.active_connections || 0 },
    { metric: "MQTT Connection Drops", value: metrics.mqtt_connection_drops_total || 0 },
  ];

  const formatValue = value => {
    if (typeof value === 'number') {
      return value.toLocaleString();
    }
    return value;
  };

  return (
    <div className="text-gray-600 my-4">
      <h2 className="text-lg font-semibold mb-2">Metrics Table</h2>
      <table className="table-auto w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">Metric</th>
            <th className="px-4 py-2">Value</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} className="border-b">
              <td className="px-4 py-2">{row.metric}</td>
              <td className="px-4 py-2">{formatValue(row.value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

MetricsTable.propTypes = {
  metrics: PropTypes.object
};

export default MetricsTable;
