import React from "react";
import Modal from "../Modal";
import close from "../../Assets/close.svg";


const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, bookingCode }) => {
    if (!isOpen) return null;
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-gray-900'>Delete Booking Detail</h2>
          <img
            onClick={onClose}
            src={close}
            alt="Close"
            className="cursor-pointer"
          />
        </div>
        <div>
          <p className='text-gray-500'>
            Do you really want to remove the booking ID <span className="font-bold">{bookingCode}</span>? 
          </p>
        </div>
        <div className="mt-8 flex justify-spacebetween gap-4">
          <button
            style={{ backgroundColor: '#FFFFFF' }}
            onClick={onClose}
            className="px-12 md:px-20 py-2 text-gray-600 border rounded"
          >
            Cancel
          </button>
          <button
            style={{ backgroundColor: '#F05D38' }}
            onClick={onConfirm}
            className="px-12 md:px-20 py-2 text-white bg-red-500 rounded"
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  };
  export default DeleteConfirmationModal