import React, { useEffect, useState } from "react";
import axios from "axios";
import CpuMemoryChart from "./CpuMemoryChart";
import EventLoopLagChart from "./EventLoopLagChart";
import HeapMemoryChart from "./HeapMemoryChart";
import GcDurationChart from "./GCDurationChart";
import MqttMessagesChart from "./MqttMessagesChart";
import HttpRequestsChart from "./HttpRequestsChart";
import ActiveConnectionsChart from "./ActiveConnectionsChart";
import MessageSizeHistogram from "./MessageSizeHistogram";
import MetricsTable from "./MetricsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import icon from "../../Assets/iconS.svg";

const MetricsVisualizer = () => {
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get("https://mdhelperservice.onrender.com/metrics");
        const data = parseMetrics(response.data);
        setMetrics(data);
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
  }, []);

  const parseMetrics = (metricsText) => {
    const lines = metricsText.split("\n");
    const parsedMetrics = {};

    lines.forEach((line) => {
      if (line.startsWith("#")) return;

      const [key, value] = line.split(" ");
      if (key && value) {
        if (key.includes("nodejs_gc_duration_seconds")) {
          // Parse histogram data
          const match = key.match(/nodejs_gc_duration_seconds_(bucket|sum|count){.*kind="(.*)"}/);
          if (match) {
            const [, type, kind] = match;
            if (!parsedMetrics.nodejs_gc_duration_seconds) {
              parsedMetrics.nodejs_gc_duration_seconds = {
                sum: {},
                count: {},
                buckets: {},
              };
            }

            if (type === "sum") {
              parsedMetrics.nodejs_gc_duration_seconds.sum[kind] = parseFloat(value);
            } else if (type === "count") {
              parsedMetrics.nodejs_gc_duration_seconds.count[kind] = parseFloat(value);
            } else if (type === "bucket") {
              const leMatch = key.match(/le="([^"]+)"/);
              if (leMatch) {
                const le = leMatch[1];
                if (!parsedMetrics.nodejs_gc_duration_seconds.buckets[kind]) {
                  parsedMetrics.nodejs_gc_duration_seconds.buckets[kind] = {};
                }
                parsedMetrics.nodejs_gc_duration_seconds.buckets[kind][le] = parseFloat(value);
              }
            }
          }
        } 
        
        if (key.includes("mqtt_message_size_bytes_bucket")) {
          // Parse histogram bucket data
          const match = key.match(/mqtt_message_size_bytes_bucket{le="([^"]+)"}/);
          if (match) {
            const [, le] = match;
            if (!parsedMetrics.mqtt_message_size_bytes) {
              parsedMetrics.mqtt_message_size_bytes = {
                buckets: {},
                sum: 0,
                count: 0,
              };
            }
            parsedMetrics.mqtt_message_size_bytes.buckets[le] = parseFloat(value);
          }
        } else if (key.includes("mqtt_message_size_bytes_sum")) {
          // Parse histogram sum
          if (!parsedMetrics.mqtt_message_size_bytes) {
            parsedMetrics.mqtt_message_size_bytes = {
              buckets: {},
              sum: 0,
              count: 0,
            };
          }
          parsedMetrics.mqtt_message_size_bytes.sum = parseFloat(value);
        } else if (key.includes("mqtt_message_size_bytes_count")) {
          // Parse histogram count
          if (!parsedMetrics.mqtt_message_size_bytes) {
            parsedMetrics.mqtt_message_size_bytes = {
              buckets: {},
              sum: 0,
              count: 0,
            };
          }
          parsedMetrics.mqtt_message_size_bytes.count = parseFloat(value);
        }
        else {
          // Parse regular metrics
          parsedMetrics[key] = parseFloat(value);
        }
      }

    });

    return parsedMetrics;
  };

  return (
    <div className={`container control md:pt-8`}>
      <div className="pb-2 md:px-8">
        <div className='md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img
            src={icon}
            alt="User"
            className=" bg-gray-100"
          />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold' style={{lineHeight:'20px', fontSize:'14px'}}>Metrics Visualization</span>
        </div>

        <div className="bg-gray-100 pt-0 md:pt-6 flex flex-col items-start lg:flex-row lg:items-center lg:justify-between md:flex-row md:items-center md:justify-between" style={{color:"#010808"}}>
          <div className="flex flex-col pb-4 md:pb-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Metrics Visualization</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>View all your Metrics Visualization.</p>
          </div>
        </div>

      {metrics ? (
      <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/3 px-4 mb-2">
            <h2 className="text-gray-600">CPU and Memory Usage</h2>
            <CpuMemoryChart metrics={metrics} />
        </div>
    
        <div class="w-full md:w-1/3 px-4 mb-2">
            <h2 className="text-gray-600">Event Loop Lag</h2>
            <EventLoopLagChart metrics={metrics} />
        </div>
    
    
        <div class="w-full md:w-1/3 px-4 mb-2">
            <h2 className="text-gray-600">MQTT Messages Received</h2>
            <MqttMessagesChart metrics={metrics} />
        </div>
    
        <div class="w-full md:w-1/3 px-4 mb-2">
            <h2 className="text-gray-600">Garbage Collection Duration</h2>
            <GcDurationChart metrics={metrics} />
        </div>
    
        <div class="w-full md:w-1/3 px-4 mb-2">
            <h2 className="text-gray-600">MQTT Message Size Distribution</h2>
            <MessageSizeHistogram metrics={metrics} />
        </div>
    
        <div class="w-full md:w-1/3 px-4 mb-2">
            <h2 className="text-gray-600">HTTP Requests</h2>
            <HttpRequestsChart metrics={metrics} />
        </div>
        
        <div class="w-full px-4">
            <MetricsTable metrics={metrics} />
        </div>
      </div>
  
   
    
      ) : (
        <p>Loading metrics...</p>
      )}
      </div>
    </div>
  );
};

export default MetricsVisualizer;