import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const EventLoopLagChart = ({ metrics }) => {
  const data = [
    {
      name: "Lag",
      value: metrics.nodejs_eventloop_lag_seconds,
    },
    {
      name: "Min Lag",
      value: metrics.nodejs_eventloop_lag_min_seconds,
    },
    {
      name: "Max Lag",
      value: metrics.nodejs_eventloop_lag_max_seconds,
    },
    {
      name: "Mean Lag",
      value: metrics.nodejs_eventloop_lag_mean_seconds,
    },
  ];

  return (
    <BarChart
      width={400}
      height={200}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="value" fill="#82ca9d" />
    </BarChart>
  );
};

export default EventLoopLagChart;